'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.ProviderMenuDisclaimer = void 0
const jsx_runtime_1 = require('react/jsx-runtime')
const ProviderMenuDisclaimer = () => {
  return (0, jsx_runtime_1.jsxs)(
    'div',
    Object.assign(
      { className: 'provider-menu__disclaimer' },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            'span',
            Object.assign({ className: 'part' }, { children: 'By connecting wallet you agree' }),
            void 0
          ),
          (0, jsx_runtime_1.jsxs)(
            'span',
            Object.assign(
              { className: 'part' },
              {
                children: [
                  'to the',
                  ' ',
                  (0, jsx_runtime_1.jsx)(
                    'a',
                    Object.assign(
                      {
                        href: 'https://docs.dex.guru/legal/terms-of-service',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      },
                      { children: 'Terms of Service' }
                    ),
                    void 0
                  ),
                  ' ',
                  'and',
                  ' ',
                  (0, jsx_runtime_1.jsx)(
                    'a',
                    Object.assign(
                      {
                        href: 'https://docs.dex.guru/legal/privacy-policy',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      },
                      { children: 'Privacy Policy' }
                    ),
                    void 0
                  ),
                ],
              }
            ),
            void 0
          ),
        ],
      }
    ),
    void 0
  )
}
exports.ProviderMenuDisclaimer = ProviderMenuDisclaimer
exports.default = exports.ProviderMenuDisclaimer
